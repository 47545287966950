import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import axios from "axios";
import { baseUrl } from "../config/Const";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const AutoCompleteAntd = ({ searchData }) => {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState("");
  const [isFocused, setIsFocused] = useState(false); // State to track focus


  const { setSelect, selectedData, type } = searchData;

  const fetchSuggestions = async (query) => {
    let suggestions= [];
    try {
      const response = await axios.get(baseUrl + searchData.api, {
        params: { term: query },
      });

      if(searchData.api === 'location'){
         suggestions = response.data?.predictions.map((item) => ({
          value: item?.description,
          ...item,
        }));
      } 
      else{
         suggestions = response.data?.result.map((item) => ({
          value: item?.category_name || item?.name,
          ...item,
        }));
      }
      
      setOptions(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearch = (value) => {
    if (value) {
      fetchSuggestions(value);
    } else {
      setOptions([]);
    }
  };

  const handleFocus = () => {
    if (searchData.api === "service_list" && !input) {
      fetchSuggestions("");
    }
    setIsFocused(true);
  };

  const handleSelect = (value) => {
    if (value) {
      const selected = options.find((option) => option.value === value);
      setInput(selected?.value);

      if(type && type === 'location'){
          setSelect(selected, 'location')
      } else{
        setSelect(selected);
      }
      
    }
  };

  useEffect(() => {
    setInput(selectedData?.value);
  }, [selectedData]);

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={input}
      dropdownClassName="custom-dropdown"
      style={{
        width: "100%",
        height: "40px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        outline: "none",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <Input
        prefix={
          searchData?.icon || <SearchOutlined style={{ color: "#797A7C" }} />
        }
        style={{
          height: "50px",
          borderRadius: "2px",
          paddingLeft: "20px",
          fontSize: "16px",
          outline: "none",
          border: "1px solid #ccc",
          borderBottom: isFocused  ? "2px solid #2a2f83" : "2px solid #ccc", 
          transition: "border-color 0.3s ease, border-bottom-color 0.3s ease",
          color: "#000",
          fontWeight: "900",
        }}
        placeholder={searchData?.placeholder || "Search"}
        className="search-input"
        onChange={(e) => {setInput(e.target.value); setSelect('');}}
        onFocus={handleFocus}
        onBlur={() => setIsFocused(false)}
        
      />
    </AutoComplete>
  );
};

export default AutoCompleteAntd;
