import JotFormReact from "jotform-react";
import React, { useState } from "react";
import V from '../asserts/recuriter.mp4';
import { images } from "../config/ConstImg";
import "../styles/Hire.css";
import playstore from "../asserts/google.png";
import appstore from "../asserts/apple.png";
import hireImg from '../asserts/hireImg01.jpg'
import FetchApi from "../config/ApiCal";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 

const HireGuard = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    siaLicense: "",
    preferredLocations: "",
    profilePhoto: null,
    countrycode: '+44',
    profilebase: ''
  });
  const [error, setError] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, profilePhoto: e.target.files[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(registerValidate()){
      setIsDisabled(true);
      storeGuard();
    }
    console.log("Form Data Submitted:", formData);
  };

  const registerValidate = () => {
    const errors = {};
    const { firstname, lastname, phone, siaLicense, preferredLocations, password, email, countrycode } = formData;

    // Validate First Name
    if (!firstname || firstname.trim() === '') {
      errors.firstname = 'First name is required';
    } else if (firstname.length < 2 || firstname.length > 50) {
      errors.firstname = 'First name must be between 2 and 50 characters';
    } else if (!/^[a-zA-Z\s]+$/.test(firstname)) {
      errors.firstname = 'First name can only contain alphabets and spaces';
    }

    // Validate Last Name
    if (!password || password.trim() === '') {
      errors.password = 'Password is required';
    } else if (password.length < 6 || password.length > 25) {
      errors.password = 'Password must be between 6 and 25 characters';
    } 

    if (!lastname || lastname.trim() === '') {
      errors.lastname = 'Last name is required';
    } else if (lastname.length < 2 || lastname.length > 50) {
      errors.lastname = 'Last name must be between 2 and 50 characters';
    } else if (!/^[a-zA-Z\s]+$/.test(lastname)) {
      errors.lastname = 'Last name can only contain alphabets and spaces';
    }

    const countryCodeRegex = /^\+\d{1,4}$/; 
    if (!countryCodeRegex.test(countrycode)) {
      errors.countrycode = 'Invalid cc';
    }

    // Validate Phone
    if (!phone || phone.trim() === '') {
      errors.phone = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = 'Mobile number must be a valid 10-digit number';
    }

    // Validate Email
    if (!email || email.trim() === '') {
      errors.email = 'Email is required';
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      errors.email = 'Please enter a valid email address';
    }

    // Validate SIA License
    if (!siaLicense || siaLicense.trim() === '') {
      errors.siaLicense = 'SIA License is required';
    }

    // Validate Preferred Locations
    if (!preferredLocations  || preferredLocations.trim() === '') {
      errors.preferredLocations = 'Preferred work location is required';
    }

    // Validate Profile Photo
    // if (!profilePhoto) {
    //   errors.profilePhoto = 'Profile photo is required';
    // }

    // If errors exist, set them and return false
    if (Object.keys(errors).length > 0) {
      setError({ ...errors, status: true });
      return false;
    }

    // Clear errors if validation passes
    setError({
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      siaLicense: '',
      preferredLocations: '',
      profilePhoto: '',
      status: false,
      countrycode: ''
    });
    return true;
  };

  const storeGuard = async() =>{
      const res = await FetchApi('auth/registerDriver', {
        email: formData.email, country_code: formData.countrycode, phone_number: formData.phone,
        password: formData.password, first_name: formData.firstname, last_name: formData.lastname, type: 'web'
        
      })
      if(res?.message == 'Registered Successfully'){
        toast.success(res?.message);
        setFormData({
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          siaLicense: "",
          preferredLocations: "",
          profilePhoto: null,
          countrycode: '+44',
          password: ''
        });
      } else if(res?.message == 'Driver Email/Phone Number already Exists'){
        toast.error('Guard already exit with  Email/Phone Number');
      } else{
        toast.error(res?.message || 'Something wrong, try again');
      }
      setIsDisabled(false);
      console.log(res);
  }


  return (
    <div className="hire-box p-3">
      <span
        className="home-service-content-text-box-txt text-center"
        style={{ marginTop: "2%" }}
      >
        Recruitment <span className="app-color"></span>
      </span>

      <div className="hire-right">
        <img
          src={images.seclist01}
          alt="security images"
          className="hire-right-img"
        />
      </div>


      <div className="container my-5">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="hire-right">
              <img
                src={images.seclist01}
                alt="security images"
                className="hire-right-img"
              />
            </div>
          </div> */}

          {/* <div className="col-lg-12"> */}
          <div className="guard-signup-container d-flex">
            {/* Left Side - Image */}
            <div className="guard-signup-image" style={{ flex: 1 }}>
              <img
                src={images.igReg}
                alt="Guard Sign-Up"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  marginLeft: "-15px"
                }}
              />
            </div>

            {/* Right Side - Form */}
            <div className="guard-signup-form-container" style={{ flex: 1, padding: "20px 0px" }}>
              <h1 className="guard-signup-heading">Create An Account</h1>

              {/* <div className="text-center">
                <span className="Login-frtext">Create An</span>{"  "}
                <span className="Login-sectext" style={{color: 'white'}}>Account</span>
              </div> */}

             
              <form onSubmit={handleSubmit} className="guard-signup-form">
                {/* Full Name */}
                <div className="row guard-signup-input-group">
                  <div className="col-md-6" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="">
                      <label className="guard-signup-label" htmlFor="fullName">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your first name"

                      />
                    </div>
                    {error?.firstname && error?.firstname != '' && (
                      <p style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.firstname}</p>
                    )}
                  </div>
                  <div className="col-md-6 lastname-margin" style={{ paddingLeft: "10px", paddingRight: "10px" }} >
                    <div className="">
                      <label className="guard-signup-label" htmlFor="fullName">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your last name"

                      />
                    </div>
                    {error?.lastname && error?.lastname != '' && (
                      <p style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.lastname}</p>
                    )}
                  </div>
                </div>

                <div className="d-flex guard-signup-input-group sign-up-phone" style={{gap:'10px'}}>
                  <div className="" style={{ width:"20%" }}>
                    <div className="">
                      <label className="guard-signup-label" htmlFor="fullName">
                        CC
                      </label>
                      <input
                        type="text"
                        id="countrycode"
                        name="countrycode"
                        value={formData.countrycode}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="CC"
                        
                      />
                    </div>
                    {error?.countrycode && error?.countrycode != '' && (
                      <p style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.countrycode}</p>
                    )}
                  </div>
                  <div className="" style={{  width:"80%" }}>
                    <div className="">
                      <label className="guard-signup-label" htmlFor="fullName">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your phone number"

                      />
                    </div>
                    {error?.phone && error?.phone != '' && (
                      <p style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.phone}</p>
                    )}
                  </div>
                </div>
                {/* Additional Fields */}
                <div className="row ">
                  {/* Email */}
                  <div className="col-md-12">
                    <div className="guard-signup-input-group">
                      <label className="guard-signup-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your email"

                      />
                    </div>
                    {error?.email && error?.email != '' && (
                      <p className="errorclass" style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.email}</p>
                    )}
                  </div>
               
                 
                </div>
                <div className="row ">             
                
                <div className="col-md-12">
                    <div className="guard-signup-input-group">
                      <div  style={{ position: "relative" }}>

                      
                      <label className="guard-signup-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"} // Toggle between text & password
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your Password"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "67%",
                          transform: "translateY(-50%)",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: "5px"
                        }}
                      >
                        {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                      </button>
                      </div>
                    </div>
                  {error?.password && error?.password != '' && (
                    <p className="errorclass" style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.password}</p>
                  )}
                </div>
              </div>
                <div className="col-md-12">
                    <div className="guard-signup-input-group">
                      <label className="guard-signup-label" htmlFor="siaLicense">
                        SIA License Number
                      </label>
                      <input
                        type="text"
                        id="siaLicense"
                        name="siaLicense"
                        value={formData.siaLicense}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter your SIA license number"

                      />
                    </div>
                    {error?.siaLicense && error?.siaLicense != '' && (
                      <p className="errorclass" style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.siaLicense}</p>
                    )}
                  </div>
                {/* Additional Fields */}
                <div className="row ">
                  <div className="col-md-12">
                    <div className="guard-signup-input-group">
                      <label className="guard-signup-label" htmlFor="preferredLocations">
                        Preferred Work Location
                      </label>
                      <input
                        type="text"
                        id="preferredLocations"
                        name="preferredLocations"
                        value={formData.preferredLocations}
                        onChange={handleChange}
                        className="guard-signup-input"
                        placeholder="Enter preferred work location"

                      />
                    </div>
                    {error?.preferredLocations && error?.preferredLocations != '' && (
                      <p className="errorclass" style={{ color: '#a9a4a4', marginTop: '5px', textAlign: 'start' }}>* {error.preferredLocations}</p>
                    )}
                  </div>
                  {/* <div className="col-md-12">
                    <div className="guard-signup-input-group">
                      <label className="guard-signup-label" htmlFor="profilePhoto">
                        Profile Photo
                      </label>
                      <input
                        type="file"
                        id="profilePhoto"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="guard-signup-input"
                        style={{ color: "#fff" }}

                      />
                    </div>
                  </div> */}
                </div>
                {/* Submit Button */}
                <div className="d-flex justify-content-end">
                  <button type="submit" className="guard-signup-button base-color" style={{ cursor: isDisabled ? "not-allowed" : "pointer",
                      opacity: isDisabled ? 0.6 : 1,}}>
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* </div> */}
        </div>

      </div>
      <div className="hire-box-content">
        <span className="hire-box-content-hd app-color">
          Insta-guard is actively seeking individuals with specific skill sets to join its team of security professionals. Here's how Insta-guard positions its recruitment campaign:
        </span>

        <div className="content-box-hire">
          <ul className='list-style-none ul-lister'>
            <li>
              <strong>Targeted Recruitment:</strong> <br /> Insta-guard is looking for security professionals who are CP qualified as-well as ex-police officers with a Close Protection License, as well as mature, experienced military professionals who are eager to contribute to their community. These individuals possess valuable skills and experience that can provide reassurance and support to those in need of urgent assistance.
            </li>
            <li>
              <strong>Flexibility and Autonomy:</strong> <br />Insta-guard offers the opportunity for individuals to plan and work according to their own schedule, providing flexibility and autonomy in managing their work diary.
            </li>
            <li>
              <strong>Diverse Opportunities:</strong> <br /> Insta-guard offers the chance to be part of a rural task force, catering to various security needs across different environments and settings.
            </li>
            <li>
              <strong>Community Impact:</strong><br /> By joining Insta-guard, individuals will contribute to their community and be a part of a pioneering service that benefits the city they live in.
            </li>
            <li>
              <strong>Competitive Compensation:</strong><br /> Insta-guard offers fantastic rates of pay to its security professionals, recognising the value of their skills and contributions.
            </li>
            <li>
              <strong>Security Automation and Innovation:</strong><br /> Insta-guard recognizes the importance of security automation and positions itself as a forward-thinking company that embraces technology to enhance security services.
            </li>
            <li>
              <strong>Join the Campaign:</strong><br /> Individuals who wish to be part of Insta-guard's mission to support the UK community are encouraged to join the campaign and subscribe to become part of the team.
            </li>
            <li>Overall, Insta-guard offers a compelling opportunity for skilled professionals to make a positive impact, contribute to community safety, and be part of an innovative and supportive company.
            </li>
            <div className="row">
              <div className="col-md-9">
                <li>
                  <strong>Public Liability Insurance:</strong><br /> Simply Business offers tailored public liability insurance for security guards from £3.49 per month. Coverage includes protection against third-party injuries and property damage, with options for additional cover such as employer’s liability, business equipment, and legal expenses insurance. Policies are customizable based on business needs, and quotes can be obtained online in about 7 minutes. Their services also include a 24/7 claims helpline and UK-based support.
                </li>
                <li>
                  For more detailed information, you can visit their <a href="https://www.simplybusiness.co.uk/insurance/public-liability/public-liability-insurance-for-security-guards/" target="blank" className="app-color">website</a>.
                </li>
                <span className="hire-box-content-hd app-color">
                  Thank you for registering your interest in joining Insta-Guard, the UK’s first automated security platform for security professionals. Follow these simple steps to become part of our innovative security solution:
                </span>
                <div>
                  <li>
                    <strong>1.Download the App:</strong> Find and download the Insta-Guard app compatible with your device.<br /><br />
                    <a href="https://play.google.com/store/apps/details?id=com.instaguard.provider" target="blank" className="me-2">
                      <img style={{ width: "100px" }} src={playstore} alt="playstore" />
                    </a>
                    <a href="https://apps.apple.com/in/app/insta-guard-guard/id6479238186" target="blank">
                      <img style={{ width: "100px" }} src={appstore} alt="appstore" />
                    </a>
                  </li>
                  <li><strong>2.Create an Account:</strong> Set up your profile with your details.</li>
                  <li><strong>3.Read Terms and Conditions:</strong> Navigate to the bottom left of the page and read our terms and conditions.</li>
                  <li><strong>4.Confirm Agreement:</strong> Tick the box to confirm your acceptance of the terms.</li>
                  <li><strong>5.Upload Documents:</strong> Provide your SIA badge and identity documents for verification.</li>
                  <li>
                    Once your documents are confirmed by our admin team, you will be onboarded onto the system. You can then
                    view and accept job opportunities that match your skills and availability.
                  </li>
                  <li>
                    <strong>Important:</strong> Only confirm your acceptance of a job if you are able to attend. Contractors who confirm a job and do not attend may be terminated from the system.
                  </li>
                  <li>We look forward to you enjoying the app, receiving great opportunities, and making a difference in your community.</li>
                </div>
              </div>
              <div className="col-md-3 mt-4">
                <img
                  src={images.aboutImg07}
                  alt="insurence images"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          </ul>

        </div>

      </div>
      <div className="container d-flex justify-content-center align-items-center my-5">
        <video className="container" style={{ maxWidth: "62em" }} src={V} controls controlsList="nodownload nofullscreen noremoteplayback"></video>
      </div>

      {/* <div className='row'>
        <div className='col-12 col-lg-6 col-md-12'>
          <JotFormReact formURL="https://form.jotform.com/233552743471053" />
        </div>
        <div className='col-12 col-lg-6 col-md-12'>

          <JotFormReact formURL="https://form.jotform.com/240893896737376" className='' />
        </div>
      </div> */}

    </div>
  );
};

export default HireGuard;
