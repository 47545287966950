import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, imageUrl } from "../config/Const";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const GuardProfileCarousel = () => {
  const [guards, setGuards] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 7;

  const fetchGuards = async () => {
    if (loading || !hasMore) return; // Prevent duplicate requests
    setLoading(true);

    try {
      const response = await axios.get(`${baseUrl}guardProfiles?offset=${offset}&limit=${limit}`);
      const newGuards = response.data?.result || [];

      if (newGuards.length === 0) {
        setHasMore(false); // No more data to load
        return;
      }

      setGuards((prevItems) => {
        const existingIds = new Set(prevItems.map((item) => item.id));
        const filteredItems = newGuards.filter((item) => !existingIds.has(item.id));
        return [...prevItems, ...filteredItems];
      });

      setOffset((prevOffset) => prevOffset + limit); // Update offset for the next fetch
    } catch (error) {
      console.error("Error fetching guards:", error);
    } finally {
      setLoading(false);
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="custom-arrow next"
        onClick={onClick} 
      >
        <FaChevronRight size={20} color="#fff" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className="custom-arrow prev"
        onClick={onClick}
      >
        <FaChevronLeft size={20} color="#fff" />
      </div>
    );
  }

  const settings = {
    infinite: true, 
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, 
    speed: 2900,
    autoplaySpeed: 2900,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (currentSlide) => {
      if (currentSlide + 2 >= guards.length && hasMore) {
        fetchGuards();
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchGuards(); 
  }, []);

  return (
    <div  style={{ width: "90%", margin: "0 auto" }}>
      <Slider {...settings}>
        {guards.map((guard, index) => (
          <div key={guard.id} style={{ padding: "10px" }}>
            <div
              className="profile-card"
              style={{
                backgroundColor: "#fff",
                margin: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                flexShrink: 0,
                marginTop: index % 2 !== 0 ? "15px" : "",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
              }}
            >
              <div style={{ padding: "16px", textAlign: "center", width: "100%", boxSizing: "border-box" }}>
                <img
                  src={`${imageUrl}uploads/${guard?.avatar}`}
                  alt="profile"
                  style={{
                    width: "130px",
                    height: "130px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginBottom: "8px",
                    border: "3px solid #2a2f83",
                    margin: "10px auto",
                    filter: "blur(5px)",
                  }}
                />
                <h4
                  style={{
                    margin: "8px 0",
                    fontSize: "16px",
                    color: "#333",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textTransform:"capitalize",
                    fontWeight:"bold"
                  }}
                >
                  {`Guard  #${guard?.id}`}
                </h4>
                <p
                  className="base-color"
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "600",
                    overflow: "hidden",
                    textOverflow: "inherit",
                    whiteSpace: "nowrap",
                  }}
                >
                  {guard?.city || "London"}
                </p>
                <p
                  className="app-color"
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {guard?.first_service_name || "Security Guard"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GuardProfileCarousel;